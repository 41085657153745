/* filepath: /D:/projetos/projetos/JavaScript/react/potfolio-react-v-2/src/components/pngtopdf/styles.css */
.create-pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.create-pdf-container input {
  margin-bottom: 10px;
}

.create-pdf-container button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.create-pdf-container button:hover {
  background-color: #45a049;
}